<template>
    <div class="box">
      <div class="dian">
        <img src="../../assets/mobile/download02.png" alt="" />
      </div>
      <div class="combo_tit">灵龙翻译助理</div>
      <div class="combo_txt" @click="centerDialogVisible=true">点击购买</div>
      <div style="position: relative;z-index: 99;">
        <div class="combo_box row" style="align-items: center;">
          <img src="../../assets/image/price_ioce02.png" alt="" srcset="">
          <span class="combo_box_text">客户端独立邀请码统一管理</span>
      </div>
      <div class="combo_box row" style="align-items: center;">
          <img src="../../assets/image/price_ioce04.png" alt="" srcset="">
          <span class="combo_box_text">多社交平台聚合一体化客户端</span>
      </div>
      <div class="combo_box row" style="align-items: center;">
          <img src="../../assets/image/price_ioce03.png" alt="" srcset="">
          <span class="combo_box_text">实时翻译不限字符数</span>
      </div>
      <!-- <div class="combo_box row" style="align-items: center;">
          <img src="../../assets/image/price_ioce05.png" alt="" srcset="">
          <span class="combo_box_text">智能联系人统计</span>
      </div> -->
      </div>
     <div>

     </div>
      <div class="base">
        <img src="../../assets/mobile/download01.png" alt="" />
      </div>
      <el-dialog :visible.sync="centerDialogVisible" custom-class="dialogClass" width="80%" center top="30%">
	   	<template #title>
	   		<div class='titleZise'>点击联系客服</div>
	   	</template>
	   	<div style="text-align:center;display: flex;align-items: center;justify-content: space-around;">
	   		<img src="../../assets/image/kefu.png" alt="">
	   		<div style="text-align:left;">
	   			<a href="https://t.me/shouzhang88" target="_blank">
	   				<p style="color:#3F8CFFFF;"> 商务Telegram: shouzhang88</p>
	   			</a>
	   			<a href="https://t.me/zkk9999999" target="_blank">
	   				<p style="color:#3F8CFFFF;"> 商务Telegram: zkk9999999</p>
	   			</a>
	   			<a href="https://t.me/linglongkeji" target="_blank">
	   				<p style="color:#3F8CFFFF;"> 客服Telegram: linglongkeji</p>
	   			</a>
	   			<a href="https://t.me/linglongchuhai" target="_blank">
	   				<p style="color:#3F8CFFFF;"> 官方频道页: 灵龙科技</p>
	   			</a>
	   		</div>
	   	</div>
	   </el-dialog> 
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
         // 点击联系客服出现弹框
			centerDialogVisible: false,
        list: [
          {
            combo:'月度套餐',
            discountPrice:'250',
            originalPrice:'300',
            validity:30,
            port:5,
          },{
            combo:'季度套餐',
            discountPrice:'700',
            originalPrice:'900',
            validity:90,
            port:10,
          },{
            combo:'年度套餐',
            discountPrice:'2500',
            originalPrice:'3200',
            validity:360,
            port:20,
          },
        ],
      };
    },
  };
  </script>
  <style scoped>
  .combo_box_text{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6D7795;
    line-height: 22px;
    margin-left: 10px;
  }
  .box {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      360deg,
      rgba(228, 247, 255, 0) 0%,
      #e4f7ff 11%,
      #e4f7ff 100%
    );
    position: relative;
  }
  .row{
    display: flex;
  }
  .wrap{
    flex-wrap: wrap;
    
  }
  .al_item_center{
    align-items: center;
  }
  .sp_between{
    justify-content: space-between;
  }
  .dian {
    position: absolute;
    top: 34px;
    left: 0;
    z-index: 9;
  }
  .position{
      z-index: 99;
  }
  .base {
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: 1;
  }
  .combo_tit{
    font-size: 32px;
    font-family: FZHZGBJW--GB1-0, FZHZGBJW--GB1;
    font-weight: bold;
    color: #222A41;
    line-height: 39px;
    padding: 41px 0 10px;
  }
  .combo_txt{
    width: 140px;
    height: 36px;
    background: #166DEF;
    box-shadow: 2px 8px 20px 0px rgba(58,137,255,0.2);
    border-radius: 30px;
    margin: 30px auto;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 36px;
  }
  .combo_box{
    width: 300px;
    height: 62px;
    background: linear-gradient(90deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.8) 100%);
    border-radius: 16px;
    border: 2px solid #FFFFFF;
    margin: 0 auto;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .combo_box_tit{
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #222A41;
    line-height: 32px;
    margin-top: 20px;
  }
  .discount_price{
    font-size: 28px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #3F8CFF;
    line-height: 32px;
    margin-top: 10px;
  }
  .original_price{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8F95B2;
    line-height: 24px;
  }
  .line_m{text-decoration:line-through}
  .combo_box_validity{
    text-align: left;
    margin-left: 10%;
    margin-top:12px;
  }
  .validity{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: bold;
    color: #222A41;
    line-height: 16px;
    margin-left: 5px;
  }
  .snap_up{
    width: 140px;
    height: 36px;
    background: #166DEF;
    box-shadow: 2px 8px 20px 0px rgba(58,137,255,0.2);
    border-radius: 30px;font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 36px;
    margin: 21px auto;
  }
  </style>